import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('.ui.sidebar.sb').sidebar({
      context: $('.ui.pushable.segment'),
      transition: 'overlay'
    }).sidebar('attach events', '#mobile_item');
  }

}
