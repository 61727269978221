import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from '../shared/data.service';

import Map from 'ol/Map';
import View from 'ol/View';
import {Vector as VectorSource, XYZ} from 'ol/source';
import {Layer, Vector as VectorLayer, Tile as TileLayer} from 'ol/layer';
import {GeoJSON,TopoJSON} from 'ol/format';
import {defaults as defaultControls, ZoomSlider,ScaleLine, MousePosition, ZoomToExtent} from 'ol/control';
import * as olProj from 'ol/proj';
import {createStringXY} from 'ol/coordinate';
import {Fill, Stroke, Style, Text, Circle} from 'ol/style';
import {Draw, Modify, Snap, Select} from 'ol/interaction';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {extend, buffer} from 'ol/extent';

import {FormGroup, FormControl, Validators} from '@angular/forms';
import * as data from "../../assets/survey/questions.json";
import {Question} from "../questionnaire/question.model";
import { features } from 'process';
declare var $: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {
  map: Map;
  layers: any =[];
  q: Question[] = (data as any).default;
  polyProps: any;
  mc: any = {}; // modal content
  clipboard: any;
  countDrawings: number = 0;

  // Reading parameters from the url:
  private sub: any; // für die Übergabe der ID in der URL
  id: number; // id of the questionnnaire (rawid, id...)
  autoid: string;
  polys: any = []; //menu on the left
  polyIds: any = []; // only the ids of polys to check by script which polygons are currently selceted for this questionnaire
  questions: any = {"173": this.q[19].no,"174": this.q[20].no,"175": this.q[21].no,"176": this.q[22].no,"177": this.q[23].no,"172": this.q[52].no};
  selectInteraction: any;
  drawInteracion: any;
  chooseInteraction: any;
  rename: boolean = false;

  // map styles:
  style: any;
  highlightStyle: any;

  // Layers
  rekefelt: any;
  drawings: any; // Layer
  drawingsource: any; // important for the interactions nad features
  bounds: any;

  constructor(private route: ActivatedRoute, private apiService: DataService) { }

  ngOnInit(){
    this.sub = this.route.params.subscribe(params => {
      if(params['id']){
        this.id = +params['id']; // (+) converts string 'id' to a number
        //this.qid = +params['qid']; // (+) converts string 'id' to a number
        this.autoid = params['autoid'];
        //console.log(this.autoid);
        // Load polys:
        this.apiService.getPolys(this.id).subscribe((res)=>{ if(res){ 
          this.polys = res; 
          res.forEach((p)=> { // To define initial style
            this.polyIds.push(p.id);
          });
          //console.log(this.polys);
        }});
        // get digitized polys and add them to the map:
        this.apiService.getDigiPolys(this.id).subscribe((res)=>{ if(res){ 
          res.forEach((p)=> { this.genFeatureFromGeom(p); });
          this.setBounds();
        }});
      }
    });
    //$('.ui.accordion').accordion();  
    $('#rating').rating();
    $(".seasonselector input").click(function(){	$("#label_"+this.name).toggleClass("activeseason"); });  //highlights the month, when selected

    this.polyProps = new FormGroup({
      'id': new FormControl(null),
      'survey': new FormControl(null),
      'rawid': new FormControl(this.id),
      'autoid': new FormControl(this.id),
      'name': new FormControl(null),
      'geom': new FormControl(null),
      'originalname': new FormControl(null),
      'digitized': new FormControl(null),
      'timeframe': new FormGroup({
        '173': new FormControl(null),
        '174': new FormControl(null),
        '175': new FormControl(null),
        '176': new FormControl(null),
        '177': new FormControl(null)
      }),
      '173': new FormGroup({
        'text': new FormControl(null),
        'seasons': new FormGroup({
          'vår': new FormControl(null),
          'sommer': new FormControl(null),
          'høst': new FormControl(null),
          'vinter': new FormControl(null),
          'helårsfelt': new FormControl(null)
        }),
        'rating': new FormControl(null)
      }),
      '174': new FormGroup({
        'text': new FormControl(null),
        'seasons': new FormGroup({
          'vår': new FormControl(null),
          'sommer': new FormControl(null),
          'høst': new FormControl(null),
          'vinter': new FormControl(null),
          'helårsfelt': new FormControl(null)
        }),
        'rating': new FormControl(null)
      }),
      '175': new FormGroup({
        'text': new FormControl(null),
        'seasons': new FormGroup({
          'vår': new FormControl(null),
          'sommer': new FormControl(null),
          'høst': new FormControl(null),
          'vinter': new FormControl(null),
          'helårsfelt': new FormControl(null)
        }),
        'rating': new FormControl(null)
      }),
      '176': new FormGroup({
        'text': new FormControl(null),
        'seasons': new FormGroup({
          'vår': new FormControl(null),
          'sommer': new FormControl(null),
          'høst': new FormControl(null),
          'vinter': new FormControl(null),
          'helårsfelt': new FormControl(null)
        }),
        'rating': new FormControl(null)
      }),
      '177': new FormGroup({
        'text': new FormControl(null),
        'seasons': new FormGroup({
          'vår': new FormControl(null),
          'sommer': new FormControl(null),
          'høst': new FormControl(null),
          'vinter': new FormControl(null),
          'helårsfelt': new FormControl(null)
        }),
        'rating': new FormControl(null)
      }),
      '172': new FormGroup({
        'text': new FormControl(null),
        'rating': new FormControl(null),
        'seasons': new FormGroup({
          'vår': new FormControl(null),
          'sommer': new FormControl(null),
          'høst': new FormControl(null),
          'vinter': new FormControl(null),
          'helårsfelt': new FormControl(null)
        }),
        'yearofconflict': new FormControl(null)
      })
    });
  }

  ngAfterViewInit() {
    // baselayers:
    let osm = new TileLayer({
      title: 'OpenStreetMap', type: 'base', visible: true,
      source: new XYZ({url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'}),          
    });
    let topo = new TileLayer({
      title: 'OpenTopoMap', type: 'base', visible: false,
      source: new XYZ({ url: '//{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png', attributions: ['Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)']})
    });
    let sea = new TileLayer({ /* This is just an overlay containing seamarks: */
      title: 'OpenSeaMap', /*type: 'base',*/ visible: true,
      source: new XYZ ({ attributions: [ 'All maps © <a href="http://www.openseamap.org/">OpenSeaMap</a>'], opaque: false, url: 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png' })
    });
    
    var zoomslider = new ZoomSlider();
    var scaleline = new ScaleLine();
    //var zoomToExtentControl = new ZoomToExtent({extent: this.bounds});
    var mouseposition = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: 'EPSG:4326',
      // comment the following two lines to have the mouse position be placed within the map.
      //className: 'custom-mouse-position',
      //target: document.getElementById('mouse-position'),
      undefinedHTML: '&nbsp;',
    });
    
    // Styles:
    this.style = new Style({ 
      fill: new Fill({ color: 'rgba(27, 72, 92, 0.4)' }),
      stroke: new Stroke({ color: '#1b485c', width: 1 }),
      text: new Text({ font: '12px Calibri,sans-serif', fill: new Fill({ color: '#000' }), stroke: new Stroke({ color: '#fff', width: 2 }) })
    });
    this.highlightStyle = new Style({
      fill: new Fill({ color: 'rgba(245, 208, 49, 0.9)' }),
      stroke: new Stroke({ color: '#fff', width: 1 }),
      text: new Text({ font: '12px Calibri,sans-serif', fill: new Fill({ color: '#000' }), stroke: new Stroke({ color: '#fff', width: 2 })})
    });
    var style_digilayer = new Style({ 
      //fill: new Fill({ color: 'rgba(245, 208, 49, 0.9)' }),
      fill: new Fill({ color: 'rgba(220, 60,20, 0.9)' }),
      stroke: new Stroke({ color: '#9b1717', width: 1 }),
      text: new Text({ font: '12px Calibri,sans-serif', fill: new Fill({ color: '#000' }), stroke: new Stroke({ color: '#fff', width: 2 })})
    });
    var style_dashed_border = new Style({ stroke: new Stroke({ color: '#9b1717', lineDash: [4], width: 2 }) });
    //var style_rekefelt = new Style({ stroke: new Stroke({ color: '#1b485c', width: 1 }), fill: new Fill({ color: 'rgba(27, 72, 92, 0.4)' })});

    this.rekefelt = new VectorLayer({ 
      //source: new VectorSource({ url: '/assets/data/rekefelt_selection.json', format: new TopoJSON() }),
      source: new VectorSource({ url: '/assets/data/rekefelt_selection.geojson', format: new GeoJSON() }),
      style: (feature)=> {
        if(this.polyIds.includes('re'+feature.get('id'))){ // highlightStyle with text
          return [
            new Style({
              fill: new Fill({ color: 'rgba(245, 208, 49, 0.9)' }),
              stroke: new Stroke({ color: '#fff', width: 1 }),
              text: new Text({ font: '12px Calibri,sans-serif', fill: new Fill({ color: '#000' }), stroke: new Stroke({ color: '#fff', width: 2 }),
              //  get the text from the feature - `this` is ol.Feature
              // and show only under certain resolution
              text: feature.get('name') })
            })
          ]
        }
        else{
          return [
            new Style({
              fill: new Fill({ color: 'rgba(27, 72, 92, 0.4)' }),
              stroke: new Stroke({ color: '#1b485c', width: 1 }),
              text: new Text({ font: '12px Calibri,sans-serif', fill: new Fill({ color: '#000' }), stroke: new Stroke({ color: '#fff', width: 2 }),
              //  get the text from the feature - `this` is ol.Feature
              // and show only under certain resolution
              text: feature.get('name') })
            })
          ]
        }
      }
    });

    // Drawings:
    this.drawingsource = new VectorSource({wrapX: false});
    this.drawingsource.on('addfeature', (evt) => {
      var feature = evt.feature;
      // Add poly to selection left:
      this.countDrawings = this.countDrawings+1;
      let id = 'draw_'+this.id+'_'+this.countDrawings;
      // console.log(id);
      // geometry für Postgresql bauen:
      let geom = this.genGeomForPostgresql(feature.getGeometry().getCoordinates(),'draw');
      // Openlayers:
      feature.setId(id);
      // TS collection:
      this.polys.push({"name": (feature.get('name') !== "" ? feature.get('name') :"drawing"),"id": id,"digitized":"t","geom": geom,"properties": feature.get('properties')});
    });
    this.drawings = new VectorLayer({ source: this.drawingsource, style: style_digilayer});

    // Layers:
    this.layers = [osm, sea, this.rekefelt, this.drawings];

    // MAP +++
    this.map = new Map({
      target: 'map', layers: this.layers,
      view: new View({ center: [1400000, 10000000], zoom: 6, minZoom:5, maxZoom: 14 }),
      controls: defaultControls().extend([ zoomslider,mouseposition,scaleline ])
      // controls: defaultControls().extend([
      //   new ZoomToExtent({
      //     extent: [
      //       813079.7791264898, 5929220.284081122,
      //       848966.9639063801, 5936863.986909639
      //     ]
      //   })
      // ]) 
    });

    // TODO: Layerswitcher:
    //let l = this.map.getLayers().getArray();

    // Selection:
    var displayFeatureInfo = (pixel) => {
      // no overlay selection in the actual sense of openlayers. I used an own collection and the featureAtPixel event handlers instead
      if( this.chooseInteraction.value !== 'draw'){
        var feature = this.map.forEachFeatureAtPixel(pixel, function (feature) { return feature; });
        if (feature) {
          if(feature.get('id')){
            let fid = 're'+feature.get('id');
            feature.setId(fid);
            //console.log(fid);
            // check if feature is already in selection:
            if(this.polyIds.includes(fid)){ // REMOVE
              //console.log('unselect: '+fid);
              //set style to normal:
              feature.setStyle(this.style);
              // remove From id-Array:
              const index = this.polyIds.indexOf(fid); if (index > -1) { this.polyIds.splice(index, 1); }
              //remove from left menu and DOM:
              const checkId = (e) => e.id == fid;
              let indexToRemove = this.polys.findIndex(checkId);
              if(indexToRemove > -1){ this.polys.splice(indexToRemove,1); }
            }
            else{ // ADD
              //console.log('select: '+fid);
              feature.setStyle(this.highlightStyle);
              this.polyIds.push(fid);
              let geom = this.genGeomForPostgresql(feature.getGeometry().getCoordinates(),'select');
              this.polys.push({"name": feature.get('name'),"id": fid, "digitized": "f", "geom": geom,"properties": {"name": feature.get('name'), "rawid": this.id.toString()}});
              // visual effect: see CSS animations
            }
            //console.log(this.polys);
          }
        }
      }
    };
    // Activate the listener:
    this.map.on('click', (e) => { displayFeatureInfo(e.pixel); });

    // Interactions +++
    //modify:
    var modify = new Modify({source: this.drawingsource}); // only modify the drawings
    modify.on('modifyend',(e) => { 
      e.features.forEach((feature) => {
        let fid = feature.getId();
        let geom = this.genGeomForPostgresql(feature.getGeometry().getCoordinates(),'draw');
        this.polys.forEach((o,i) => {
          if(o.id == fid){ 
            if(o.geom !== geom){ // only the feature that has changed
              //console.log("modified: "+fid);
              o.geom = geom; // save to collection
              // TODO:save to db or wait for the modal?
              
            }
          }
        });
      });
      //console.log(this.polys); 
    });

    //draw:
    var draw, snap; // global so we can remove it later

    this.chooseInteraction = document.getElementById('chooseInteraction') as HTMLInputElement; // Dropdown
    this.chooseInteraction.onchange = () => {
      if( this.chooseInteraction.value == 'draw'){
        this.map.removeInteraction(draw);
        draw = new Draw({ source: this.drawingsource, type: 'Polygon', });
        this.map.addInteraction(draw);
        this.map.addInteraction(modify);
      }
      else{ // default = select by click, not by select interaction:
        this.map.removeInteraction(draw);
        this.map.removeInteraction(snap);
        this.map.removeInteraction(modify);
      }
    };  
    // Interactions ---
  }
  ngOnDestroy(){
    // seems a little bit clunky, but it prevents zombie modals:
    $('.ui.modal').remove();
  }

  zoomToFeature(p){
    //console.log('zoomToFeature: -'+featureid+'-');
    // This syntax stops, when there is a match:
    //console.log(p.id);
    let feature: any;
    if(p.digitized == 'f'){
      feature = this.rekefelt.getSource().forEachFeature((f) => {
        if(p.id == 're'+f.get('id')){ return(f); }
      });
    }
    else{
      feature = this.drawingsource.forEachFeature((f) => {
        if(p.id == f.getId()){ return(f); }
      });
    }
    if(feature){
      //this.map.getView().fit(feature.getGeometry().getExtent(), this.map.getSize());
      this.map.getView().fit(feature.getGeometry().getExtent(), {padding: [120, 80, 120, 80]});
    }
  }
  unselectRegion(p,i){ // Selections
    let fid = p.id;
    //console.log('remove: '+fid);
    // remove From id-Array:
    const index = this.polyIds.indexOf(fid); if (index > -1) { this.polyIds.splice(index, 1); }
    // remove from left menu and DOM:
    this.polys.splice(i,1);
    if(fid.substring(0,4)!=='draw'){ // select, reset map style
      let feature = this.rekefelt.getSource().getFeatureById(fid);
      if(feature){ feature.setStyle(this.style); }
    }
    else{ // drawing, remove drawing from source:
      let feature = this.drawingsource.getFeatureById(fid);
      if(feature){ this.drawingsource.removeFeature(feature); }
    }
  }
  openModal(p,index){ 
    //event.stopPropagation();
    this.polyProps.reset(); // kill remaining entries
    this.rename = false; //reset on opening
    $('.ui.modal').modal('show');
    if(typeof p.name !== 'undefined')
      this.mc.name = p.name;
    else{
      if(typeof p.properties !== 'undefined')
        this.mc.name = p.properties.name !== null ? p.properties.name : "no name given";
      else
        this.mc.name = "no name given";
    }
    this.mc.index = index;
    //console.log('open modal '+this.mc.name+' id: '+p.id);
    this.polyProps.get('id').patchValue(p.id);
    this.polyProps.get('rawid').patchValue(this.id);
    this.polyProps.get('survey').patchValue(7);
    this.polyProps.get('geom').patchValue(p.geom);
    this.polyProps.get('autoid').patchValue(this.autoid);
    this.polyProps.get('digitized').patchValue(p.digitized);
    this.polyProps.get('name').patchValue(this.mc.name);

    if(this.polys[index].renamed){
      this.mc.originalname = this.polys[index].originalname;
      this.polyProps.get('originalname').patchValue(this.polys[index].originalname);
      this.mc.renamed = true;
    }
    else{
      this.mc.originalname= null;
      this.mc.renamed = false;
    }
   // console.log(p.properties.timeframe);
    //this.polyProps.get('name').patchValue(p.properties.name);
    //this.polyProps.get('173.text').patchValue(p.properties['173'].text);


    
    let geoq = [173,174,175,176,177,172];
    geoq.forEach((e) => {
      let i = e.toString();
      $("#label_"+i).removeClass("activetimeframe"); // reset class before we set it
      this.polyProps.get(i+'.text').patchValue(null);
      if(typeof p.properties !== 'undefined'){
        if(typeof p.properties[i] !== 'undefined'){
          if(i !== '172'){ // 172: conflicts, no seasons
            // activate checkbox:
            if(typeof p.properties.timeframe !== 'undefined'){
              // FIXME:
              if(p.properties.timeframe[e]){
                this.polyProps.get('timeframe.'+i).patchValue(true);
                $("#label_"+i).addClass("activetimeframe");
              }
            }
            else{ //generate timeframe
              this.polyProps.get('timeframe.'+i).patchValue(true);
              $("#label_"+i).addClass("activetimeframe");
            }
            //reset seasons class:
            $("#label_"+i+"_vår").removeClass("activeseason");
            $("#label_"+i+"_sommer").removeClass("activeseason");
            $("#label_"+i+"_høst").removeClass("activeseason");
            $("#label_"+i+"_vinter").removeClass("activeseason");
            $("#label_"+i+"_helårsfelt").removeClass("activeseason");
            //patch seasons:
            if(typeof p.properties[i].seasons !== 'undefined'){
              for (const [k, val] of Object.entries(p.properties[i].seasons)) {
                if(val == true || val == 't'){
                  this.polyProps.get(i+'.seasons.'+k).patchValue(true); // sets the form value
                  $("#label_"+i+"_"+k).addClass("activeseason");  //highlights the month, when selected
                }
              }
            }
          }
          else{
            this.polyProps.get('172.yearofconflict').patchValue(p.properties['172'].yearofconflict);
          }
          if(typeof p.properties[i].text !== 'undefined'){
            //console.log(p.properties[i].text);
            this.polyProps.get(i+'.text').patchValue(p.properties[i].text);
          }
        }
      }
    });

    //$('#rating').rating('set rating',p.properties.rating);
    //this.polyProps.get('rating').patchValue(p.properties.rating);
  }
  handleName(p){ // shows a placeholder in the list, if there is no name
    if(p.name){ return p.name; }
    else if(typeof p.properties !== 'undefined'){ 
      if(p.properties.name){ return p.properties.name; }}
    else{ return "ID "+p.id+": digitized (no name)"; }
  }
  toggleRename(index){ // for the input field
    if(this.rename == false){ this.rename = true; }
    else{ // on save:
      if(!this.polys[index].renamed){
        this.polys[index].originalname = this.polys[index].properties.name; // save original name, only the first time, when it is renamed to keep the first name
        
      }
      this.polys[index].renamed = true;
      this.mc.renamed = true;
      this.mc.originalname = this.polys[index].originalname;
      this.polyProps.get('originalname').patchValue(this.polys[index].originalname);

      this.mc.name = this.polyProps.get('name').value; 
      this.polys[index].name = this.polyProps.get('name').value;
      this.rename = false; }
      //console.log(this.polys[index]);
      //console.log(this.mc);
  }
  copyToClipboard(p){
    $('body').toast({class: 'success',message: `copied data from `+this.handleName(p)+` to clipboard`});
    this.clipboard = p.properties;
    //console.log(this.clipboard);
  }
  paste(p,index){
    for (const [k, val] of Object.entries(this.clipboard)) {
      if(k != 'name' && val !='')
        this.polys[index].properties[k] = val; 
    }
    $('body').toast({class: 'success',message: `pasted data into `+this.handleName(p)});
  }
  onSubmit(){
    //this.polyProps.get('rating').patchValue($('#rating').rating('get rating'));
    //console.log('Form submitted for index: '+this.mc.index);
    //console.log(this.polyProps.value);
    //console.log(this.polys[this.mc.index].properties);
    
    // Save to angular:
    this.polys[this.mc.index].properties = this.polyProps.value;

    // Save to db:
    //console.log(this.polys[this.mc.index].properties);

    this.apiService.updatePoly(this.polyProps.value).subscribe((res)=>{ 
      //console.log(res);
       if(res.status == 'ok'){
         $('body').toast({class: 'success',message: `region saved`});
       }
       else{
         $('body').toast({class: 'error',message: `An error occured `});
       }
    });
    
  }
  genGeomForPostgresql(coordinates,type){ // converts the coordinates from the openlayers feature to postgis
    let out = [];
    if(type == 'draw'){
      coordinates[0].forEach((e) => { out.push(e[0]+' '+e[1]); });
    }
    else{ //select
      coordinates[0][0].forEach((e) => { out.push(e[0]+' '+e[1]); });
    }
    let geom = 'SRID=3857;MULTIPOLYGON((('+out.join()+')))';
    //console.log(geom);
    return geom;
  }

  genFeatureFromGeom(p){
    let geom = p.geom.substring(15,p.geom.length -3);
    let geomArr = geom.split(",");
    let geomArr2 = [];
    geomArr.forEach((o) => {
      let coordinates = o.split(' ');
      geomArr2.push(olProj.fromLonLat([coordinates[0],coordinates[1]],"EPSG:4326"));
    });
    //console.log('[['+geomArr2.join()+']]');
    let feature = new Feature({
      geometry: new Polygon([geomArr2]),
      name: this.handleName(p),
      id: p.id,
      properties: p.properties
    });
    feature.setId(p.id);
    this.drawingsource.addFeature(feature);
  }

  setBounds(){
    let bounds = this.drawingsource.getExtent();
      this.polyIds.forEach((id) => {
        //let feature = this.rekefelt.getSource().getFeatureById(id);
        let featureExtent = this.rekefelt.getSource().forEachFeature((f) => {
          if(id == 're'+f.get('id')){ return f.getGeometry().getExtent(); }
        });
        if(featureExtent){ bounds = extend(bounds, featureExtent); } // erweitere den Extent
      });
      this.bounds = bounds;
      this.map.getView().fit(this.bounds,{padding: [80, 80, 80, 80]});
  }

}