<app-header></app-header>
<div class="ui pushable segment">
  <app-mobile-menu></app-mobile-menu>
  <div class="pusher">
    <div class="ui container">
        <h1>Intervjuundersøkelse av rekefiskere langs norskekysten</h1>
        <form [formGroup]="questionnaire" (ngSubmit)="onSubmit()" class="ui form warning">
            <!-- <div class="field"><label>{{q[]}}</label></div> -->

            <div formGroupName="metaData">
                <div class="field"><input type="hidden" name="id" class="form-control" formControlName="id"></div>
                <div class="field"><label>AutoID:</label><input type="text" name="autoid" class="form-control" formControlName="autoid"></div>
                <div class="field"><div class="ui toggle checkbox"><label>test modus</label><input type="checkbox" name="test" class="form-control" formControlName="test" tabindex="0" class="hidden"></div></div>
                <label for="q1">1. {{q[1].no}}</label><div class="inline fields"><div class="field" *ngFor="let e of q[1].options">
                    <div class="ui radio checkbox"><input type="radio" name="Intervjuer" formControlName="Intervjuer" [value]="e" tabindex="0" class="hidden form-control"><label>{{e}}</label></div>
                </div></div> 
                <div *ngIf="!questionnaire.get('metaData.Intervjuer').valid" class="ui message">{{q[1].help}}</div>

            </div>

            <h2 class="ui dividing header">generell informasjon</h2>
            <div formGroupName="info">
                <div class="field"><label>2. {{q[2].no}}</label><input type="number" min="0" max="140" step="1" name="q2" class="form-control" formControlName="q2"></div>
                <div *ngIf="!questionnaire.get('info.q2').valid && questionnaire.get('info.q2').touched" class="ui message">{{q[2].help}}</div>
                <div class="field"><label>3. {{q[3].no}}</label><textarea name="q3" class="form-control" formControlName="q3"></textarea></div>
                <label>4. {{q[4].no}}</label>
                <div class="fields">
                    <div class="two wide field"><label>siden året:</label><input type="number" min="1900" max="2050" step="1" placeholder="2000" name="q4_year" class="form-control" formControlName="q4_year"></div>
                    <div class="two wide field"><label>antall år:</label><input type="number" min="0" max="140" step="1" placeholder="0" name="q4_duration" class="form-control" formControlName="q4_duration"></div>
                </div>
                <label>5. {{q[5].no}}</label>
                <div class="fields">
                    <div class="two wide field"><label>siden året:</label><input type="number" min="1900" max="2050" step="1" placeholder="2000" name="q5_year" class="form-control" formControlName="q5_year"></div>
                    <div class="two wide field"><label>antall år:</label><input type="number" min="0" max="150" step="1" placeholder="0" name="q5_duration" class="form-control" formControlName="q5_duration"></div>
                </div>
                <label>6. {{q[6].no}}</label>
                <div class="fields">
                    <div class="two wide field"><label>lengde i meter:</label><input type="number" min="0" max="200" step="1" placeholder="0" name="q6_meter" class="form-control" formControlName="q6_meter"></div>
                    <div class="two wide field"><label>lengde i fot:</label><input type="number" min="0" max="600" step="1" placeholder="0" name="q6_feet" class="form-control" formControlName="q6_feet"></div>
                </div>
                <label>7. {{q[7].no}}</label>
                <div class="fields">
                    <div class="two wide field"><input type="number" min="0" max="100" step="1" name="q7" class="form-control" formControlName="q7"></div>
                </div>
            </div>

            <h2 class="ui dividing header">Vi vil gjerne vite mer om fiskemønsteret ditt gjennom året og hvordan du driver.</h2>
            <div formGroupName="fiskemønsteret">
                <label>8. {{q[8].no}}</label><div class="field"><textarea name="q8" class="form-control" formControlName="q8"></textarea></div>
                <label>9. {{q[9].no}}</label><div class="fields">
                        <table class="medium-12 columns monthselector" formGroupName="q9">
                            <tbody>
                                <tr class="month">
                                    <td id="label_month_1">januar</td>
                                    <td id="label_month_2">februar</td>
                                    <td id="label_month_3">mars</td>
                                    <td id="label_month_4">april</td>
                                    <td id="label_month_5">maj</td>
                                    <td id="label_month_6">juni</td>
                                    <td id="label_month_7">juli</td>
                                    <td id="label_month_8">august</td>
                                    <td id="label_month_9">september</td>
                                    <td id="label_month_10">oktober</td>
                                    <td id="label_month_11">november</td>
                                    <td id="label_month_12">desember</td>
                                </tr>
                                <tr>
                                    <td><input class="form-control" formControlName="month_1" name="month_1" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_2" name="month_2" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_3" name="month_3" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_4" name="month_4" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_5" name="month_5" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_6" name="month_6" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_7" name="month_7" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_8" name="month_8" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_9" name="month_9" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_10" name="month_10" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_11" name="month_11" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="month_12" name="month_12" type="checkbox"></td>
                                </tr>
                            </tbody>
                        </table>
                </div>
                <label>10. {{q[10].no}}</label><div class="field"><textarea name="q10" class="form-control" formControlName="q10"></textarea></div>
                <label>11. {{q[11].no}}</label><div class="field"><textarea name="q11" class="form-control" formControlName="q11"></textarea></div>
                <label>12. {{q[12].no}}</label><div class="fields"><div class="two wide field"><input type="number" min="0" step="1" name="q12" class="form-control" formControlName="q12"></div></div>
                <label>13. {{q[13].no}}</label><div class="fields"><div class="two wide field"><input type="number" min="0" step="1" name="q13" class="form-control" formControlName="q13"></div></div>
                <label>14. {{q[14].no}}</label><div class="fields"><div class="two wide field"><input type="number" min="0" step="1" name="q14" class="form-control" formControlName="q14"></div></div>
                <label>15. {{q[15].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q15" formControlName="q15" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q15" formControlName="q15" value="nei"><label>nei</label></div></div>
                </div>
                <label>16. {{q[16].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q16" formControlName="q16" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q16" formControlName="q16" value="nei"><label>nei</label></div></div>
                </div>
                <label>17. {{q[17].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q17" formControlName="q17" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q17" formControlName="q17" value="nei"><label>nei</label></div></div>
                </div>

                <div [ngClass]="questionnaire.get('fiskemønsteret.q17').value == 'ja'? 'viz' : 'hid'">
                <label>18. {{q[18].no}}</label>
                <div class="field"><textarea name="q10" class="form-control" formControlName="q10"></textarea></div>
                </div>
            </div>

            <h2 class="ui dividing header">Vi vil gjerne vite mer om hvor du fisker, og eventuelle endringer over tid i driftsmønster og fangst</h2>
            <div formGroupName="rekefelt">
                <label>19. {{q[19].no}}</label><div class="fields"><button class="ui green button" type="button" (click)="saveAnGoToMap($event)">åpne kart</button></div>
                <label>20. {{q[20].no}}</label><div class="fields"><button class="ui green button" type="button" (click)="saveAnGoToMap($event)">åpne kart</button></div>
                <label>21. {{q[21].no}}</label><div class="fields"><button class="ui green button" type="button" (click)="saveAnGoToMap($event)">åpne kart</button></div>
                <label>22. {{q[22].no}}</label><div class="fields"><button class="ui green button" type="button" (click)="saveAnGoToMap($event)">åpne kart</button></div>
                <label>23. {{q[23].no}}</label><div class="fields"><button class="ui green button" type="button" (click)="saveAnGoToMap($event)">åpne kart</button></div>
                <label>24. {{q[24].no}}</label><div class="fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q24" formControlName="q24" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q24" formControlName="q24" value="nei"><label>nei</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q24" formControlName="q24" value="vet ikke"><label>vet ikke</label></div></div>
                </div>

                <div [ngClass]="questionnaire.get('rekefelt.q24').value == 'ja'? 'viz' : 'hid'">
                    <label>25. {{q[25].no}}</label>
                    <div class="fields" formGroupName="q25">
                        <div class="field"><div class="ui checkbox"><input type="checkbox" name="smak" formControlName="smak"><label>smak</label></div></div>
                        <div class="field"><div class="ui checkbox"><input type="checkbox" name="skallkvalitet" formControlName="skallkvalitet"><label>skallkvalitet</label></div></div>
                        <div class="field"><div class="ui checkbox"><input type="checkbox" name="rogn" formControlName="rogn"><label>rogn</label></div></div>
                        <div class="field"><div class="ui checkbox"><input type="checkbox" name="annet" formControlName="annet"><label>annet</label></div></div>
                    </div>
                </div>
                <div [ngClass]="questionnaire.get('rekefelt.q25.annet').value == true ? 'viz' : 'hid'">
                    <label>26. {{q[26].no}}</label><div class="field"><textarea name="q26" class="form-control" formControlName="q26" placeholder="fritekst"></textarea></div>
                </div>

                <label>27. {{q[27].no}}</label>
                <div class="fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q27" formControlName="q27" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q27" formControlName="q27" value="nei"><label>nei</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q27" formControlName="q27" value="vet ikke"><label>vet ikke</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('rekefelt.q27').value == 'ja' ? 'viz' : 'hid'">
                    <label>28. {{q[28].no}}</label>
                    <div class="fields" formGroupName="q28">
                        <div class="field"><div class="ui checkbox"><input type="checkbox" name="større_utbredelse" formControlName="større_utbredelse"><label>større utbredelse</label></div></div>
                        <div class="field"><div class="ui checkbox"><input type="checkbox" name="mindre_utbredelse" formControlName="mindre_utbredelse"><label>mindre utbredelse</label></div></div>
                        <div class="field"><div class="ui checkbox"><input type="checkbox" name="større_fangster" formControlName="større_fangster"><label>større fangster</label></div></div>
                        <div class="field"><div class="ui checkbox"><input type="checkbox" name="lavere_fangster" formControlName="lavere_fangster"><label>lavere fangster</label></div></div>
                    </div>
                    <label>29. {{q[29].no}}</label><div class="field"><textarea name="q29" class="form-control" formControlName="q29" placeholder="fritekst"></textarea></div>
                </div>
                <label>30. {{q[30].no}}</label><div class="fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q30" formControlName="q30" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q30" formControlName="q30" value="nei"><label>nei</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q30" formControlName="q30" value="vet ikke"><label>vet ikke</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('rekefelt.q30').value == 'ja' ? 'viz' : 'hid'">
                    <label>31. {{q[31].no}}</label><div class="field"><textarea name="q31" class="form-control" formControlName="q31" placeholder="fritekst"></textarea></div>
                </div>
            </div>

            <h2 class="ui dividing header">Mer langvarige forandringer</h2>
            <div formGroupName="forandringer">
                <label>32. {{q[32].no}}</label><div class="fields" formGroupName="q32">
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="lite_reker" formControlName="lite_reker"><label>lite reker</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="mye_maneter" formControlName="mye_maneter"><label>mye maneter</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="avstand_til_leveransested" formControlName="avstand_til_leveransested"><label>avstand til leveransested</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="avstand_til_hjemmehavn" formControlName="avstand_til_hjemmehavn"><label>avstand til hjemmehavn</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="drivstoff_og_økonomi" formControlName="drivstoff_og_økonomi"><label>drivstoff og økonomi</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="innblanding_av_undermåls_reke_og_fiskeyngel" formControlName="innblanding_av_undermåls_reke_og_fiskeyngel"><label>innblanding av undermåls reke og fiskeyngel</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="værforhold" formControlName="værforhold"><label>værforhold</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="annet" formControlName="annet"><label>annet</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('forandringer.q32.annet').value == true ? 'viz' : 'hid'">
                    <label>33. {{q[33].no}}</label><div class="field"><textarea name="q33" class="form-control" formControlName="q33" placeholder="fritekst"></textarea></div>
                </div>

                <label>34. {{q[34].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q34" formControlName="q34" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q34" formControlName="q34" value="nei"><label>nei</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('forandringer.q34').value == 'ja' ? 'viz' : 'hid'">
                    <label>35. {{q[35].no}}</label><div class="field"><textarea name="q35" class="form-control" formControlName="q35" placeholder="fritekst"></textarea></div>
                </div>
                <label>36. {{q[36].no}}</label><div class="fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q36" formControlName="q36" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q36" formControlName="q36" value="nei"><label>nei</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q36" formControlName="q36" value="vet ikke"><label>vet ikke</label></div></div>
                </div>
                <label>37. {{q[37].no}}</label><div class="fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q37" formControlName="q37" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q37" formControlName="q37" value="nei"><label>nei</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q37" formControlName="q37" value="vet ikke"><label>vet ikke</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('forandringer.q37').value == 'ja' ? 'viz' : 'hid'">
                    <label>38. {{q[38].no}}</label><div class="fields">
                        <div class="field"><div class="ui radio checkbox"><input type="radio" name="q38" formControlName="q38" value="ytterfelt i eksponerte områder"><label>ytterfelt i eksponerte områder</label></div></div>
                        <div class="field"><div class="ui radio checkbox"><input type="radio" name="q38" formControlName="q38" value="felt i beskyttet skjærgård"><label>felt i beskyttet skjærgård</label></div></div>
                        <div class="field"><div class="ui radio checkbox"><input type="radio" name="q38" formControlName="q38" value="fjordfelt"><label>fjordfelt</label></div></div>
                        <div class="field"><div class="ui radio checkbox"><input type="radio" name="q38" formControlName="q38" value="likt over alle felt"><label>likt over alle felt</label></div></div>
                    </div>
                </div>
                <label>39. {{q[39].no}}</label><div class="fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q39" formControlName="q39" value="enkeltfelt"><label>enkeltfelt</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q39" formControlName="q39" value="større områder"><label>større områder</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q39" formControlName="q39" value="ikke aktuelt"><label>ikke aktuelt</label></div></div>
                </div>
                <label>40. {{q[40].no}}</label><div class="fields" formGroupName="q40">
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="mye_maneter" formControlName="mye_maneter"><label>mye maneter</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="avstand_til_leveransested" formControlName="avstand_til_leveransested"><label>avstand til leveransested</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="avstand_til_hjemmehavn" formControlName="avstand_til_hjemmehavn"><label>avstand til hjemmehavn</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="drivstoff_og_økonomi" formControlName="drivstoff_og_økonomi"><label>drivstoff og økonomi</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="innblanding_av_undermåls_reke_og_fiskeyngel" formControlName="innblanding_av_undermåls_reke_og_fiskeyngel"><label>innblanding av undermåls reke og fiskeyngel</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="værforhold" formControlName="værforhold"><label>værforhold</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="annet" formControlName="annet"><label>annet</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('forandringer.q40.annet').value == true ? 'viz' : 'hid'">
                    <label>41. {{q[41].no}}</label><div class="field"><textarea name="q41" class="form-control" formControlName="q41" placeholder="fritekst"></textarea></div>
                </div>
                <label>42. {{q[42].no}}</label><div class="field"><textarea name="q42" class="form-control" formControlName="q42" placeholder="fritekst"></textarea></div>
                <label>43. {{q[43].no}}</label><div class="field"><textarea name="q43" class="form-control" formControlName="q43" placeholder="fritekst"></textarea></div>
            </div>

            <h2 class="ui dividing header">Er det noen konflikter knyttet til rekefiske i området, eller andre forhold som påvirker bruket ditt?</h2>
            <div formGroupName="konflikter">
                <label>44. {{q[44].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q44" formControlName="q44" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q44" formControlName="q44" value="nei"><label>nei</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('konflikter.q44').value == 'ja' ? 'viz' : 'hid'">
                    <label>45. {{q[45].no}}</label><div class="fields">
                        <div class="field"><div class="ui radio checkbox"><input type="radio" name="q45" formControlName="q45" value="teiner"><label>teiner</label></div></div>
                        <div class="field"><div class="ui radio checkbox"><input type="radio" name="q45" formControlName="q45" value="garn"><label>garn</label></div></div>
                        <div class="field"><div class="ui radio checkbox"><input type="radio" name="q45" formControlName="q45" value="snurpenot"><label>snurpenot</label></div></div>
                        <div class="field"><div class="ui radio checkbox"><input type="radio" name="q45" formControlName="q45" value="annet"><label>annet</label></div></div>
                    </div>
                </div>
                <div [ngClass]="questionnaire.get('konflikter.q45').value == 'annet' ? 'viz' : 'hid'">
                    <label>46. {{q[46].no}}</label><div class="fields"><textarea name="q46" class="form-control" formControlName="q46" placeholder="fritekst"></textarea></div>
                </div>
                <label>47. {{q[47].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q47" formControlName="q47" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q47" formControlName="q47" value="nei"><label>nei</label></div></div>
                </div>
                <label>48. {{q[48].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q48" formControlName="q48" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q48" formControlName="q48" value="nei"><label>nei</label></div></div>
                </div>
                <label>49. {{q[49].no}}</label><div class="fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q49" formControlName="q49" value="fortøyninger i rekefelt"><label>fortøyninger i rekefelt</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q49" formControlName="q49" value="nedslamming av rekefelt"><label>nedslamming av rekefelt</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q49" formControlName="q49" value="medikamentell avslusning"><label>medikamentell avslusning</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q49" formControlName="q49" value="tømming av brønnbåter i rekefelt"><label>tømming av brønnbåter i rekefelt</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q49" formControlName="q49" value="annet"><label>annet</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('konflikter.q49').value == 'annet' ? 'viz' : 'hid'">
                    <label>50. {{q[50].no}}</label><div class="field"><textarea name="q50" class="form-control" formControlName="q50" placeholder="fritekst"></textarea></div>
                </div>
                <label>51. {{q[51].no}}</label><div class="fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q51" formControlName="q51" value="holder meg unna"><label>holder meg unna</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q51" formControlName="q51" value="sjekker forekomst av reker"><label>sjekker forekomst av reker</label></div></div>
                </div>
                <label>52. {{q[52].no}}</label><div class="field"><button class="ui green button" type="button" (click)="saveAnGoToMap($event)">åpne kart</button></div>
                <label>53. {{q[53].no}}</label><div class="field"><textarea name="q53" class="form-control" formControlName="q53" placeholder="fritekst"></textarea></div>

                <label>54. {{q[54].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q54" formControlName="q54" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q54" formControlName="q54" value="nei"><label>nei</label></div></div>
                </div>
                <label>55. {{q[55].no}}</label><div class="fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q55" formControlName="q55" value="mye mer reker"><label>mye mer reker</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q55" formControlName="q55" value="mer reker"><label>mer reker</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q55" formControlName="q55" value="mindre reke"><label>mindre reke</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q55" formControlName="q55" value="rekene har forsvunnet"><label>rekene har forsvunnet</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q55" formControlName="q55" value="annet"><label>annet</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('konflikter.q55').value == 'annet' ? 'viz' : 'hid'">
                    <label>56. {{q[56].no}}</label><div class="field"><textarea name="q56" class="form-control" formControlName="q56" placeholder="fritekst"></textarea></div>
                </div>
                <label>57. {{q[57].no}}</label><div class="fields" formGroupName="q57">
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="nei" formControlName="nei"><label>nei</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="gruvedrift" formControlName="gruvedrift"><label>gruvedrift</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="dumping_av_stein" formControlName="dumping_av_stein"><label>dumping av stein</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="industri" formControlName="industri"><label>industri</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="rørledninger" formControlName="rørledninger"><label>rørledninger</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="strømkabler" formControlName="strømkabler"><label>strømkabler</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="verneområder" formControlName="verneområder"><label>verneområder</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="militære_øvingsområder" formControlName="militære_øvingsområder"><label>militære øvingsområder</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="annet" formControlName="annet"><label>annet</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('konflikter.q57.annet').value == true ? 'viz' : 'hid'">
                    <label>58. {{q[58].no}}</label><div class="field"><textarea name="q58" class="form-control" formControlName="q58" placeholder="fritekst"></textarea></div>
                </div>
            </div>

            <h2 class="ui dividing header">Andre spørsmål knyttet til drift/organisering</h2>
            <div formGroupName="andre">
                <label>59. {{q[59].no}}</label><div class="fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q59" formControlName="q59" value="fiskemottak"><label>fiskemottak</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q59" formControlName="q59" value="direkte til forbruker"><label>direkte til forbruker</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q59" formControlName="q59" value="begge deler"><label>begge deler</label></div></div>
                </div>
                <label>60. {{q[60].no}}</label><div class="fields" formGroupName="q60">
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="avstand_til_fiskemottak" formControlName="avstand_til_fiskemottak"><label>avstand til fiskemottak</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="bedre_pris_ved_kaisalg" formControlName="bedre_pris_ved_kaisalg"><label>bedre pris ved kaisalg</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="åpningstider_på_fiskemottak" formControlName="åpningstider_på_fiskemottak"><label>åpningstider på fiskemottak</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="kaisalg_opprettholder_kystkultur" formControlName="kaisalg_opprettholder_kystkultur"><label>kaisalg opprettholder kystkultur</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="faste_leveringsavtaler_med_for_eksempel_Hurtigruten" formControlName="faste_leveringsavtaler_med_for_eksempel_Hurtigruten"><label>faste leveringsavtaler med for eksempel Hurtigruten</label></div></div>
                    <div class="field"><div class="ui checkbox"><input type="checkbox" name="annet" formControlName="annet"><label>annet</label></div></div>
                </div>
                <div [ngClass]="questionnaire.get('andre.q60.annet').value == true ? 'viz' : 'hid'">
                    <label>61. {{q[61].no}}</label><div class="field"><textarea name="q61" class="form-control" formControlName="q61" placeholder="fritekst"></textarea></div>
                </div>

                <label>62. {{q[62].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q62" formControlName="q62" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q62" formControlName="q62" value="nei"><label>nei</label></div></div>
                </div>
                <label>63. {{q[63].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q63" formControlName="q63" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q63" formControlName="q63" value="nei"><label>nei</label></div></div>
                </div>
                <label>64. {{q[64].no}}</label><div class="inline fields">
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q64" formControlName="q64" value="ja"><label>ja</label></div></div>
                    <div class="field"><div class="ui radio checkbox"><input type="radio" name="q64" formControlName="q64" value="nei"><label>nei</label></div></div>
                </div>
                <label>65. {{q[65].no}}</label><div class="field"><textarea name="q65" class="form-control" formControlName="q65" placeholder="fritekst"></textarea></div>
            </div>


            


            <button class="ui blue button" type="submit">lagre</button> <button class="ui black button" *ngIf="enableReset" (click)="resetQuestionnaire()">Insert new questionnaire</button>
        </form>
        <div>&nbsp;</div>
    </div>
  </div>
</div>
<app-footer></app-footer>