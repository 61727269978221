import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { MapComponent} from './map/map.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { ListViewComponent } from './backend/list-view/list-view.component';

const routes: Routes = [
  { path: '', redirectTo: 'q', pathMatch: 'full' },
  { path: 'q', component: QuestionnaireComponent },
  { path: 'q/:id', component: QuestionnaireComponent },
  { path: 'map', component: MapComponent },
  { path: 'map/:id/:autoid', component: MapComponent },
  { path: 'login', component: DashboardComponent},
  { path: 'login/questionnaires', component: ListViewComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
