<app-header></app-header>
<div class="ui pushable segment">
    <app-mobile-menu></app-mobile-menu>
    <div class="pusher">
        <div class="ui container">
            <h1>admin dashboard</h1>
            <div class="ui link cards">

            <div class="ui card">
              <div class="content">
                <a class="header" routerLink="questionnaires">Lagret intervjuer</a>
                <div class="meta">
                  <span class="date">Sist oppdatert: {{qinfo.lastentry}}</span>
                </div>
                <div class="description">
                  Liste med alle intervjuer
                </div>
              </div>
              <div class="extra content">
                <a>
                  <i class="file alternate icon"></i>
                  <a routerLink="questionnaires"> {{qinfo.count}} intervjuer totalt</a>
                </a>
              </div>
            </div>

            <div class="ui card">
              <div class="content">
                <a class="header" routerLink="questionnaires">Eksport data</a>
                <div class="meta">
                  <span class="date"></span>
                </div>
                <div class="description">
                  Eksporterer resultater til forskjellige formater
                </div>
              </div>
              <div class="extra content">
                <i class="download icon"></i>
                <a href="https://geosurvey.infodocks.de/api/questionnaires" target="_blank" title="non geoferenced answers as JSON">JSON</a> | 
                <a target="_blank" title="geodata as GeoJSON">GeoJSON</a>
              </div>
            </div>

            <div class="ui card">
              <div class="content">
                <a class="header" routerLink="/map">Kartvisning</a>
                <div class="meta">
                  <span class="date"></span>
                </div>
                <div class="description">
                  Vise geodata av alle intervjuer
                </div>
              </div>
              <div class="extra content">
                <a routerLink="/map">
                  <i class="map icon"></i>
                  {{qinfo.countpolydigized}} polygons newlydigitized, <br> {{qinfo.countpolyselected}} given areas selected
                </a>
              </div>
            </div>

            <div class="ui card">
              <div class="content">
                <a class="header" routerLink="questionnaires">statistics</a>
                <div class="meta">
                  <span class="date"></span>
                </div>
                <div class="description">
                  show basic statistics
                </div>
              </div>
              <div class="extra content">
                <a>
                  <i class="chart pie icon"></i>
                  <!-- <a routerLink="questionnaires"> {{qinfo.count}} questionnaires in total</a> -->
                </a>
              </div>
            </div>

            <div class="ui card">
              <div class="content">
                <a class="header" routerLink="questionnaires">questions</a>
                <div class="meta">
                  <span class="date"></span>
                </div>
                <div class="description">
                  exports the questions to:
                </div>
              </div>
              <div class="extra content">
                <i class="download icon"></i>
                <a href="https://geosurvey.infodocks.de/api/questionnaires/questions" target="_blank" title="question list as JSON">JSON</a> 
              </div>
            </div>
            
            </div>
            <div>&nbsp;</div>
        </div>
    </div>
</div>
<app-footer></app-footer>
