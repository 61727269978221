import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
declare var $: any;

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit {
  questionnaires: any = [];
  tests: any = [];
  recycle: any = [];

  versions: any = [];
  versionsSingleQ: any = [];
  mc: any = []; // modal content

  constructor(private apiService: DataService) { }

  ngOnInit() {
    this.apiService.getList().subscribe((res)=>{ if(res){ this.questionnaires = res; } });
    this.apiService.getTests().subscribe((res)=>{ if(res){ this.tests = res; } });
    this.apiService.getRecycle().subscribe((res)=>{ if(res){ this.recycle = res; } });

    this.apiService.getVersionOverview().subscribe((res)=>{ 
      if(res){ 
        this.versions = res; 

        // adds the version count:
        this.questionnaires.forEach( e => {
          if(this.versions[e.id]){ e['count'] = parseInt(this.versions[e.id].count); }
          else{ e['count'] = 1; }
        });
      }
    });
    $('.menu .item').tab();
  }

  openVersions(id){
    this.mc.id = id;
    this.apiService.getVersions(id).subscribe((res)=>{ 
      if(res){ this.versionsSingleQ = res; }
    });

    $('#versions').modal('show');
  }

  removeFromList(id){
    // remove from db:
    let status: any= [];
    status['id'] = parseInt(id);
    status['status'] = 4;
    // console.log('remove from list: '+id);
    // console.log(status);
    this.apiService.setStatus(status).subscribe((res)=>{ 
      if(res.status == 'ok'){
        let index;
        this.questionnaires.forEach((obj,i)=>{
          if(obj.id == id){
            // remove from angular list:
            this.questionnaires.splice(i,1);
            // add to recycle list:
            this.recycle.unshift(obj);
          }
        });
        $('body').toast({class: 'success',message: `questionnaire moved to recycle bin`});
      }
      else{ $('body').toast({class: 'error',message: `An error occured `}); }
    });
  }
  removeFromTests(id){// finally delete (set status to 9)
    $('#delete').modal({onApprove : ()=> {
      console.log('delete: '+id);
      // remove from db:
      let status: any= [];
      status['id'] = parseInt(id);
      status['status'] = 9;
      this.apiService.setStatus(status).subscribe((res)=>{ 
        if(res.status == 'ok'){
          let index;
          this.tests.forEach((obj,i)=>{
            if(obj.id == id){
              // remove from angular list:
              this.tests.splice(i,1);
            }
          });
          $('body').toast({class: 'success',message: `questionnaire deleted`});
        }
        else{ $('body').toast({class: 'error',message: `An error occured `}); }
      });
    }}).modal('show');
  }
  restore(id){ // adds to list, remove from recycle
    let status: any= [];
    status['id'] = parseInt(id);
    status['status'] = 4;
    this.apiService.setStatus(status).subscribe((res)=>{ 
      if(res.status == 'ok'){
        let index;
        this.recycle.forEach((obj,i)=>{
          if(obj.id == id){
            // remove from recycle list:
            this.recycle.splice(i,1);
            // add to list:
            this.questionnaires.unshift(obj);
          }
        });
        $('body').toast({class: 'success',message: `questionnaire restored`});
      }
      else{ $('body').toast({class: 'error',message: `An error occured `}); }
    });
  }
  delete(id){ // finally delete (set status to 9)
    $('#delete').modal({onApprove : ()=> {
      console.log('delete: '+id);
      // remove from db:
      let status: any= [];
      status['id'] = parseInt(id);
      status['status'] = 9;
      this.apiService.setStatus(status).subscribe((res)=>{ 
        if(res.status == 'ok'){
          let index;
          this.recycle.forEach((obj,i)=>{
            if(obj.id == id){
              // remove from angular list:
              this.recycle.splice(i,1);
            }
          });
          $('body').toast({class: 'success',message: `questionnaire deleted`});
        }
        else{ $('body').toast({class: 'error',message: `An error occured `}); }
      });
    }}).modal('show');
  }
  removeVersionFromList(id){
    // remove from db:
    let status: any= [];
    status['id'] = parseInt(id);
    status['status'] = 4;
    // console.log('remove from list: '+id);
    // console.log(status);
    /*
    this.apiService.setStatus(status).subscribe((res)=>{ 
      if(res.status == 'ok'){
        let index;
        this.versionsSingleQ.forEach((obj,i)=>{
          if(obj.id == id){
            // remove from angular list:
            this.versionsSingleQ.splice(i,1);
            // FIXME: (kein Recycling für Versionen?)
            // add to recycle list:
            //this.recycle.unshift(obj);
          }
        });
        $('body').toast({class: 'success',message: `questionnaire moved to recycle bin`});
      }
      else{ $('body').toast({class: 'error',message: `An error occured `}); }
    });
    */
  }

}
