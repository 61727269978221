import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../shared/data.service';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders} from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  qinfo: any = {}; // questionnaires meta info

  constructor(private route: ActivatedRoute, private apiService: DataService, private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.apiService.getQinfo().subscribe((res)=>{ 
      if(res){ this.qinfo = res; }
    });
  }

}
