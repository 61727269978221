<div class="ui container" id="top">
    <div class="ui grid">
        <div class="four wide column"><a href="https://www.hi.no" target="_blank"><img src="../assets/img/HI logo farger norsk.jpg"></a></div>
        <div class="eight wide column"><a href="http://www.nordlandsforskning.no/" target="_blank"><img src="../assets/img/defaultlogoJubileum.png"></a></div>
        <div class="four wide column"><a href="https://www.fiskeridir.no/" target="_blank"><img src="../assets/img/FD_inverted.png"></a></div>
    </div>
</div>

<div class="ui grid" id="mainmenu">
    <div class="computer only row">
        <div class="column cp">
          <div class="ui menu">
            <a class="item" routerLink="/">Spørreskjema</a>
            <a class="item" routerLink="/info">Om</a>
            <a class="item" routerLink="/login"><i class="icon user circle"></i>Pålogg</a>
          </div>
        </div>
    </div>
  
    <div class="tablet mobile only row">
      <div class="column">
        <div class="ui menu">
          <a id="mobile_item" class="item"><i class="bars icon"></i></a>
        </div>
      </div>
    </div>
  
</div>