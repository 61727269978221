import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { MapComponent } from './map/map.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { MobileMenuComponent } from './core/mobile-menu/mobile-menu.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { ListViewComponent } from './backend/list-view/list-view.component';
import { UserAdminComponent } from './backend/user-admin/user-admin.component';
import { MapAdminComponent } from './backend/map-admin/map-admin.component';


@NgModule({
  declarations: [
    AppComponent,
    QuestionnaireComponent,
    MapComponent,
    HeaderComponent,
    FooterComponent,
    MobileMenuComponent,
    DashboardComponent,
    ListViewComponent,
    UserAdminComponent,
    MapAdminComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
