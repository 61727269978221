import { Component, OnInit, ɵCodegenComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import * as data from "../../assets/survey/questions.json";
import{Question} from "./question.model";
import { DataService } from '../shared/data.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';

declare var $: any;

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  q: Question[] = (data as any).default;
  autoid: string = 'Start';
  questionnaire: FormGroup;
  currentYear: number = new Date().getFullYear();
  validating: boolean = false; // status to prevent recursion during validation

  id: number = 0;
  private sub: any; // für die Übergabe der ID in der URL
  enableReset = false; // enables the reset button

  constructor(private route: ActivatedRoute, private router: Router, private apiService: DataService){ }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if(params['id']){
        this.id = +params['id']; // (+) converts string 'id' to a number
        // console.log('ID: '+this.id);
        this.fillInExistingValues();
      }
      else{
        this.apiService.getAutoid().subscribe((res)=>{ 
          if(res){ 
            this.questionnaire.get('metaData.autoid').patchValue(res['autoid']); 
            this.autoid = res['autoid'];
          }
        });
      }
    });
    $(".monthselector input").click(function(){	$("#label_"+this.name).toggleClass("activemonth"); });  //highlights the month, when selected

    this.questionnaire = new FormGroup({
      'metaData': new FormGroup({
        'autoid': new FormControl(this.autoid),
        'id': new FormControl(this.id),
        'test': new FormControl(false),
        'Intervjuer': new FormControl(null, Validators.required)
      }),
      'info':new FormGroup({
        'q2': new FormControl(null, [Validators.required]),
        'q3': new FormControl(null),
        'q4_year': new FormControl(null,[Validators.required, this.validateYear.bind(this)]),
        'q4_duration': new FormControl(null,[Validators.required, this.validateDuration.bind(this)]),
        'q5_year': new FormControl(null,[Validators.required, this.validateYear5.bind(this)]),
        'q5_duration': new FormControl(null,[Validators.required, this.validateDuration5.bind(this)]),
        'q6_meter': new FormControl(null,[Validators.required, this.validateMeter.bind(this)]),
        'q6_feet': new FormControl(null,[Validators.required, this.validateFeet.bind(this)]),
        'q7': new FormControl(null,[this.validate7]),
      }),
      'fiskemønsteret': new FormGroup({
        'q8': new FormControl(null),
        'q9': new FormGroup({
          'month_1': new FormControl(null),
          'month_2': new FormControl(null),
          'month_3': new FormControl(null),
          'month_4': new FormControl(null),
          'month_5': new FormControl(null),    
          'month_6': new FormControl(null),
          'month_7': new FormControl(null),
          'month_8': new FormControl(null),
          'month_9': new FormControl(null),
          'month_10': new FormControl(null),
          'month_11': new FormControl(null),
          'month_12': new FormControl(null)
        }),
        'q10': new FormControl(null),
        'q11': new FormControl(null),
        'q12': new FormControl(null),
        'q13': new FormControl(null),
        'q14': new FormControl(null),
        'q15': new FormControl(null, [Validators.required]),
        'q16': new FormControl(null, [Validators.required]),
        'q17': new FormControl(null),
        'q18': new FormControl(null, [Validators.required]),
      }),
      'rekefelt': new FormGroup({
        'q19': new FormControl(null, [Validators.required]),
        'q20': new FormControl(null, [Validators.required]),
        'q21': new FormControl(null, [Validators.required]),
        'q22': new FormControl(null, [Validators.required]),
        'q23': new FormControl(null, [Validators.required]),
        'q24': new FormControl(null, [Validators.required]),
        'q25': new FormGroup({
          'smak': new FormControl(null),
          'skallkvalitet': new FormControl(null),
          'rogn': new FormControl(null),
          'annet': new FormControl(null)
        }),
        'q26': new FormControl(null),
        'q27': new FormControl(null, [Validators.required]),
        'q28': new FormGroup({
          'større_utbredelse': new FormControl(null),
          'mindre_utbredelse': new FormControl(null),
          'større_fangster': new FormControl(null),
          'lavere_fangster': new FormControl(null)
        }),
        'q29': new FormControl(null),
        'q30': new FormControl(null, [Validators.required]),
        'q31': new FormControl(null, [Validators.required]),
      }),
      'forandringer': new FormGroup({
        'q32': new FormGroup({
          'lite_reker': new FormControl(null),
          'mye_maneter': new FormControl(null),
          'avstand_til_leveransested': new FormControl(null),
          'avstand_til_hjemmehavn': new FormControl(null),
          'drivstoff_og_økonomi': new FormControl(null),
          'innblanding_av_undermåls_reke_og_fiskeyngel': new FormControl(null),
          'værforhold': new FormControl(null),
          'annet': new FormControl(null)
        }),
        'q33': new FormControl(null),
        'q34': new FormControl(null, [Validators.required]),
        'q35': new FormControl(null),
        'q36': new FormControl(null, [Validators.required]),
        'q37': new FormControl(null, [Validators.required]),
        'q38': new FormControl(null),
        'q39': new FormControl(null, [Validators.required]),
        'q40': new FormGroup({
          'mye_maneter': new FormControl(null),
          'avstand_til_leveransested': new FormControl(null),
          'avstand_til_hjemmehavn': new FormControl(null),
          'drivstoff_og_økonomi': new FormControl(null),
          'innblanding_av_undermåls_reke_og_fiskeyngel': new FormControl(null),
          'værforhold': new FormControl(null),
          'annet': new FormControl(null),
        }),
        'q41': new FormControl(null),
        'q42': new FormControl(null),
        'q43': new FormControl(null),
      }),
      'konflikter': new FormGroup({
        'q44': new FormControl(null, [Validators.required]),
        'q45': new FormControl(null),
        'q46': new FormControl(null),
        'q47': new FormControl(null, [Validators.required]),
        'q48': new FormControl(null, [Validators.required]),
        'q49': new FormControl(null),
        'q50': new FormControl(null),
        'q51': new FormControl(null, [Validators.required]),
        'q52': new FormControl(null),
        'q53': new FormControl(null),
        'q54': new FormControl(null, [Validators.required]),
        'q55': new FormControl(null),
        'q56': new FormControl(null),
        'q57': new FormGroup({
          'nei': new FormControl(null),
          'gruvedrift': new FormControl(null),
          'dumping_av_stein': new FormControl(null),
          'industri': new FormControl(null),
          'rørledninger': new FormControl(null),
          'strømkabler': new FormControl(null),
          'verneområder': new FormControl(null),
          'militære_øvingsområder': new FormControl(null),
          'annet': new FormControl(null)
        }),
        'q58': new FormControl(null)
      }),
      'andre': new FormGroup({
        'q59': new FormControl(null, [Validators.required]),
        'q60': new FormGroup({
          'avstand_til_fiskemottak': new FormControl(null),
          'bedre_pris_ved_kaisalg': new FormControl(null),
          'åpningstider_på_fiskemottak': new FormControl(null),
          'kaisalg_opprettholder_kystkultur': new FormControl(null),
          'faste_leveringsavtaler_med_for_eksempel_Hurtigruten': new FormControl(null),
          'annet': new FormControl(null)
        }),
        'q61': new FormControl(null),
        'q62': new FormControl(null, [Validators.required]),
        'q63': new FormControl(null, [Validators.required]),
        'q64': new FormControl(null, [Validators.required]),
        'q65': new FormControl(null)
      })
    })
  }

  ngAfterViewInit(): void {
    $('.ui.checkbox').checkbox();
  }

  onSubmit(){
    this.saveQuestionnaire();
  }
  saveQuestionnaire(){
     //console.log('Form submitted');
     //console.log(this.questionnaire.value);
     this.apiService.updateQuestionnaire(this.questionnaire.value).subscribe((res)=>{ 
       console.log(res);
       if(res.status == 'ok'){
         $('body').toast({class: 'success',message: `questionnaire saved`});
         this.enableReset = true;
         // get the new id from the insert an set it to set the reference for the map
         if(res.id > 0){
           this.id = res.id;
           this.questionnaire.get("metaData.id").patchValue(res.id);
         }
       }
       else{
         $('body').toast({class: 'error',message: `An error occured `});
       }
     });
     
     //this.router.navigate(['/login/questionnaires']);
     // Nach dem Abschicken zurücksetzen:
     // this.questionnaire.reset();
  }
  resetQuestionnaire(){
    //reset autoid
    this.apiService.getAutoid().subscribe((res)=>{ 
      if(res){ this.questionnaire.get('metaData.autoid').patchValue(res['autoid']); }
    });
    //reset id
    this.id = 0;
    //reset questtionnaire
    this.questionnaire.reset();
    //scroll up to the start
    window.scroll(0,0);
    //hide reset button
    this.enableReset = false;
  }
  saveAnGoToMap(event:any){
    event.stopPropagation();
    //console.log('Form submitted');
    console.log(this.questionnaire.value);
    this.apiService.updateQuestionnaire(this.questionnaire.value).subscribe((res)=>{ 
      console.log(res);
      //FIXME: Doppelter toast, weil eine subscription besteht?
      if(res.status == 'ok'){
        $('body').toast({class: 'success',message: `questionnaire saved`});
        this.enableReset = true;
        // get the new id from the insert an set it to set the reference for the map
        if(res.id > 0){
          this.id = res.id;
          this.questionnaire.get("metaData.id").patchValue(res.id);
        }
        this.router.navigate(['/map/'+this.id+'/'+this.autoid]);
      }
      else{
        $('body').toast({class: 'error',message: `An error occured `});
      }
    });
    
  }
  
  // Q4:
  validateYear(control: FormControl): {[s: string]: boolean}{
    var v = control.value;
    if(v >= 1900 && v <= this.currentYear){
      if(!this.validating){
        this.validating = true;
        this.questionnaire.get('info.q4_duration').patchValue(this.currentYear - v);
      }
      else{ this.validating = false; } // reset
      return null;
    }
    return {'year is not valid': true};
  }
  validateDuration(control: FormControl): {[s: string]: boolean}{
    var v = control.value;
    if(v){
      if(v >=0 && v <= 140){
        if(!this.validating){
          this.validating = true;
          this.questionnaire.get('info.q4_year').patchValue(this.currentYear - v);
        } 
        else{ this.validating = false; } // reset
        return null;
      }
    }
    return {'duration is not valid': true};
  }
  // Q5:
  validateYear5(control: FormControl): {[s: string]: boolean}{
    var v = control.value;
    if(v >= 1900 && v <= this.currentYear){
      if(!this.validating){
        this.validating = true;
        this.questionnaire.get('info.q5_duration').patchValue(this.currentYear - v);
      }
      else{ this.validating = false; } // reset
      return null;
    }
    return {'year is not valid': true};
  }
  validateDuration5(control: FormControl): {[s: string]: boolean}{
    var v = control.value;
    if(v){
      if(v >=0 && v <= 140){
        if(!this.validating){
          this.validating = true;
          this.questionnaire.get('info.q5_year').patchValue(this.currentYear - v);
        } 
        else{ this.validating = false; } // reset
        return null;
      }
    }
    return {'duration is not valid': true};
  }
  // Q6:
  validateMeter(control: FormControl): {[s: string]: boolean}{
    var v = control.value;
    if(v){
      if(v >= 0 && v <= 200){
        if(!this.validating){
          this.validating = true;
          v = Math.floor(((v*3.05) + Number.EPSILON) * 100) / 100;
          this.questionnaire.get('info.q6_feet').patchValue(v);
        }
        else{ this.validating = false; } // reset
        return null;
      }
    }
    return {'lenght is not valid': true};
  }
  validateFeet(control: FormControl): {[s: string]: boolean}{
    var v = control.value;
    if(v){
      if(v >=0 && v <= 600){
        if(!this.validating){
          this.validating = true;
          v = Math.floor(((v/3.05) + Number.EPSILON) * 100) / 100;
          this.questionnaire.get('info.q6_meter').patchValue(v);
        } 
        else{ this.validating = false; } // reset
        return null;
      }
    }
    return {'lenght is not valid': true};
  }
  validate7(control: FormControl): {[s: string]: boolean}{
    var v = control.value;
    if(v >= 0 && v <= 100){
      return null;
    }
    return {'this seems to be not valid': true};
  }

  fillInExistingValues(){ //new structure
    if(this.id){
      let a: any; //answers q is already in use
      this.apiService.getAnswer(this.id).subscribe((res)=>{ 
        if(res){ 
          a = res; a = a.data;
          this.autoid = a.metaData.autoid;
          // insert values, only if they exist:
          for (const [k1, v1] of Object.entries(a)) {
            for (const [k2, v2] of Object.entries(v1)) {
              let field = this.questionnaire.get(k1+'.'+k2);
              if(field){
               if(v2){ field.patchValue(v2); }
              }
            }
          }
        }
      });
    }
  }

}