import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Qlistelement } from '../classes/qlistelement';
import { Qinfo } from '../classes/qinfo';
import { Answer } from '../classes/answer';
import { Questionnaire } from '../classes/questionnaire';
import { Poly } from '../classes/poly';
import { Versions } from '../classes/versions';
import { Status } from '../classes/status';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  apiURL: string = 'https://geosurvey.infodocks.de/api/';
  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': '4dmYsZZAHx2HuRyix9yjgqzJrEC8P4VKurmgdP6QUdNuhS2RjF5MPrWJkudwx',
      'Access-Control-Allow-Origin':'*'
    })
  };

  constructor(private httpClient: HttpClient) { }

  public getQinfo(url?: string): Observable<Qinfo>{
    return this.httpClient.get<Qinfo>(`${this.apiURL}questionnaires/info`)
      .pipe(
        // tap(_ => this.log('fetched Qinfo')),
        catchError(this.handleError<Qinfo>('getQinfo'))
      );
  }

  public getList(url?: string): Observable<Qlistelement[]>{
    return this.httpClient.get<Qlistelement[]>(`${this.apiURL}questionnaires`)
      .pipe(
        // tap(_ => this.log('fetched Qlist')),
        catchError(this.handleError<Qlistelement[]>('getList', []))
      );
  }
  public getTests(url?: string): Observable<Qlistelement[]>{
    return this.httpClient.get<Qlistelement[]>(`${this.apiURL}questionnaires/tests`)
      .pipe(
        // tap(_ => this.log('fetched Qlist')),
        catchError(this.handleError<Qlistelement[]>('getTests', []))
      );
  }
  public getRecycle(url?: string): Observable<Qlistelement[]>{
    return this.httpClient.get<Qlistelement[]>(`${this.apiURL}questionnaires/recycle`)
      .pipe(
        // tap(_ => this.log('fetched Qlist')),
        catchError(this.handleError<Qlistelement[]>('getRecycle', []))
      );
  }
  public getVersions(id: number): Observable<Qlistelement[]>{
    return this.httpClient.get<Qlistelement[]>(`${this.apiURL}questionnaires/`+id+`/versions`)
      .pipe(
        // tap(_ => this.log('fetched Qlist')),
        catchError(this.handleError<Qlistelement[]>('getVersions', []))
      );
  }
  public getAnswer(id: number): Observable<Answer[]>{
    return this.httpClient.get<Answer[]>(`${this.apiURL}questionnaires/`+id)
      .pipe(
        // tap(_ => this.log('fetched answer')),
        catchError(this.handleError<Answer[]>('getAnswer', []))
      );
  }
  public getAutoid(){
    return this.httpClient.get(`${this.apiURL}autoid`)
      .pipe(catchError(this.handleError('getAutoid')));
  }
  // public getPoly(id: string): Observable<Poly[]>{
  //   return this.httpClient.get<Poly[]>(`${this.apiURL}poly/`+id)
  //     .pipe(
  //       // tap(_ => this.log('fetched Poly')),
  //       catchError(this.handleError<Poly[]>('getSelectedPoly', []))
  //     );
  // }
  public getPolys(id: number): Observable<Poly[]>{
    return this.httpClient.get<Poly[]>(`${this.apiURL}questionnaires/`+id+"/polys")
      .pipe(
        // tap(_ => this.log('fetched Poly')),
        catchError(this.handleError<Poly[]>('getPolys', []))
      );
  }
  public getDigiPolys(id: number): Observable<Poly[]>{
    return this.httpClient.get<Poly[]>(`${this.apiURL}questionnaires/`+id+"/digipolys")
      .pipe(
        // tap(_ => this.log('fetched Poly')),
        catchError(this.handleError<Poly[]>('getDigiPolys', []))
      );
  }
  public getVersionOverview(url?: string): Observable<Versions[]>{
    return this.httpClient.get<Versions[]>(`${this.apiURL}questionnaires/versions`)
      .pipe(
        // tap(_ => this.log('fetched Qlist')),
        catchError(this.handleError<Versions[]>('getLigetVersionOverviewst', []))
      );
  }

  public updateQuestionnaire(questionnaire:Questionnaire){
    //console.log(Questionnaire);
    const params = new FormData();
    for ( var key in questionnaire ) {
      params.append(key, JSON.stringify(questionnaire[key]));
    }
    params.append('authkey','4dmYsZZAHx2HuRyix9yjgqzJrEC8P4VKurmgdP6QUdNuhS2RjF5MPrWJkudwx');
    return this.httpClient.post(`${this.apiURL}questionnaires/update`,params)
      .pipe(
        map((res:any) => { return res}),
        //tap(_ => this.log('questionnaire saved')),
        catchError(this.handleError<Questionnaire[]>('updateQuestionnaire'))
      );
  }
  public updatePoly(poly:Poly){
    //console.log(Questionnaire);
    const params = new FormData();
    for ( var key in poly ) {
      params.append(key, JSON.stringify(poly[key]));
    }
    params.append('authkey','4dmYsZZAHx2HuRyix9yjgqzJrEC8P4VKurmgdP6QUdNuhS2RjF5MPrWJkudwx');
    return this.httpClient.post(`${this.apiURL}poly/update`,params)
      .pipe(
        map((res:any) => { return res}),
        //tap(_ => this.log('update Poly')),
        catchError(this.handleError<Poly[]>('updatePoly'))
      );
  }
  public setStatus(status:Status){
    const params = new FormData();
    for ( var key in status ) {
      params.append(key, JSON.stringify(status[key]));
    }
    params.append('authkey','4dmYsZZAHx2HuRyix9yjgqzJrEC8P4VKurmgdP6QUdNuhS2RjF5MPrWJkudwx');
    return this.httpClient.post(`${this.apiURL}questionnaires/setstatus`,params)
      .pipe(
        map((res:any) => { return res}),
        catchError(this.handleError<Status[]>('setStatus'))
      );
  }


  //_______________________________________________helper +++

  /**
    * Handle Http operation that failed.
    * Let the app continue.
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) { // Eigentlich als Benachrichtigungsfunktion innerhalb der Website gedacht.
    console.log(`API-Service: ${message}`);
  }


}
