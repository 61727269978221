<div class="ui grid" id="mainmenu">
    <div class="computer only row">
        <div class="column p">
          <div class="ui menu">
            <a class="item" routerLink="/q/{{id}}"> <i class="angle left icon"></i>Tilbake til spørreskjema</a>
            <a class="item"></a>
            <a class="item">Kart for intervju {{autoid}}</a>
            
          </div>
        </div>
    </div>
    <div class="tablet mobile only row">
      <div class="column">
        <div class="ui menu">
          <a id="mobile_item" class="item"><i class="bars icon"></i></a>
        </div>
      </div>
    </div>
</div>

<div id="content" class="ui grid">
    <div id="menu-col" class="four wide column">
        <div id="status"></div>
        <label>Modus:  </label>
        <select id="chooseInteraction">
            <option selected value="select">Utvalg</option>
            <option value="draw">Tegning</option>
        </select>
        <label> fit to extent:  </label><i class="expand arrows alternate icon" (click)="setBounds()"></i>
        <table id="polytable" class="ui celled striped table">
            <thead><tr><th></th><th>Feltnavn</th><th></th></tr></thead>
            <tbody><tr *ngFor="let p of polys; index as i" class="justselected {{p.id}}" [ngClass]="{'worktodo': p.timeframe =='undefined'}">
                <td><a (click)="unselectRegion(p,i)" title="Fjern felt fra utvalg"><i class="trash icon"></i></a> </td>
                <td><a (click)="openModal(p,i)">{{handleName(p)}}</a></td>
                <td>
                    <a (click)="openModal(p,i)" title="Redigere egenskaper"><i class="edit icon"></i></a> 
                    <a (click)="zoomToFeature(p)" title="Zoom til felt"><i class="map marker alternate icon"></i></a>
                    <a (click)="copyToClipboard(p)" title="Kopierer egenskaper fra felt"><i class="copy icon"></i></a>
                    <a *ngIf="clipboard" (click)="paste(p,i)" title="Lim inn egenskaper"><i class="paste icon"></i></a>
                </td>
            </tr></tbody>
        </table>       
    </div>
    <div id="map-col"class="twelve wide column"> <div id="map" #mapElement></div> </div>
</div>

<div class="ui modal longer">
    <i class="close icon"></i>
    <div class="header">{{mc.question}}</div>
    <div class="content scrolling ">
        <form [formGroup]="polyProps" (ngSubmit)="onSubmit()" class="ui form warning">
            <input type="hidden" name="id" class="form-control" formControlName="id">
            <input type="hidden" name="survey" class="form-control" formControlName="survey">
            <input type="hidden" name="rawid" class="form-control" formControlName="rawid">
            <input type="hidden" name="autoid" class="form-control" formControlName="autoid">
            <input type="hidden" name="geom" class="form-control" formControlName="geom">
            <input type="hidden" name="digitized" class="form-control" formControlName="digitized">
            <input type="hidden" name="originalname" class="form-control" formControlName="originalname">
            <div class="description">
                <div class="ui header grid">
                    <div class="fourteen wide column">
                        <span *ngIf="!rename">{{mc.name}} <a title="rename" (click)="toggleRename(mc.index)"><i class="pen icon"></i></a></span>
                        <div *ngIf="rename" class="ui input"><input type="text" name="name" class="form-control" formControlName="name"><a title="save name" (click)="toggleRename(mc.index)"><i class="save icon"></i></a> </div>
                        <span *ngIf="mc.renamed">({{mc.originalname}})</span>
                </div>
                    <div id="modaltoolbar" class="two wide column "><!--<a title="copy content"><i class="copy icon"></i></a> <a title="paste content"><i class="paste icon"></i></a>--></div>
                </div>
                <div class="field">
                    <label>rating:</label>
                    <div id="rating" class="ui black rating" data-rating="2.5" data-max-rating="5"></div>
                </div>
                <div class="field">
                    <table class="medium-12 columns seasonselector" formGroupName="timeframe">
                        <tbody>
                            <tr class="timeframe">
                                <td id="label_173">2019-2020</td>
                                <td id="label_174">2015-2018</td>
                                <td id="label_175">10 år</td>
                                <td id="label_176">20 år</td>
                                <td id="label_177">1970- og 1980-tallet</td>
                            </tr>
                            <tr>
                                <td><input class="form-control" formControlName="173" name="173" type="checkbox"></td>
                                <td><input class="form-control" formControlName="174" name="174" type="checkbox"></td>
                                <td><input class="form-control" formControlName="175" name="175" type="checkbox"></td>
                                <td><input class="form-control" formControlName="176" name="176" type="checkbox"></td>
                                <td><input class="form-control" formControlName="177" name="177" type="checkbox"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                

                <div class="period" formGroupName="173" [hidden]="!this.polyProps.get('timeframe.173').value">
                    <label>{{this.questions['173']}}: </label>
                    <!-- <input type="hidden" name="rating" class="form-control" formControlName="rating"> -->
                    <div class="field">
                        <table class="medium-12 columns seasonselector" formGroupName="seasons">
                            <tbody>
                                <tr class="season">
                                    <td id="label_173_vår">vår</td>
                                    <td id="label_173_sommer">sommer</td>
                                    <td id="label_173_høst">høst</td>
                                    <td id="label_173_vinter">vinter</td>
                                    <td id="label_173_helårsfelt">helårsfelt</td>
                                </tr>
                                <tr>
                                    <td><input class="form-control" formControlName="vår" name="173_vår" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="sommer" name="173_sommer" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="høst" name="173_høst" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="vinter" name="173_vinter" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="helårsfelt" name="173_helårsfelt" type="checkbox"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="field"><textarea name="text" class="form-control" formControlName="text"></textarea></div>
                </div>
                <div class="period" formGroupName="174" [hidden]="!this.polyProps.get('timeframe.174').value">
                    <label>{{this.questions['174']}}: </label>
                    <div class="field">
                        <table class="medium-12 columns seasonselector" formGroupName="seasons">
                            <tbody>
                                <tr class="season">
                                    <td id="label_174_vår">vår</td>
                                    <td id="label_174_sommer">sommer</td>
                                    <td id="label_174_høst">høst</td>
                                    <td id="label_174_vinter">vinter</td>
                                    <td id="label_174_helårsfelt">helårsfelt</td>
                                </tr>
                                <tr>
                                    <td><input class="form-control" formControlName="vår" name="174_vår" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="sommer" name="174_sommer" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="høst" name="174_høst" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="vinter" name="174_vinter" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="helårsfelt" name="174_helårsfelt" type="checkbox"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="field"><textarea name="text" class="form-control" formControlName="text"></textarea></div>
                </div>
                <div class="period" formGroupName="175" [hidden]="!this.polyProps.get('timeframe.175').value">
                    <label>{{this.questions['175']}}: </label>
                    <div class="field">
                        <table class="medium-12 columns seasonselector" formGroupName="seasons">
                            <tbody>
                                <tr class="season">
                                    <td id="label_175_vår">vår</td>
                                    <td id="label_175_sommer">sommer</td>
                                    <td id="label_175_høst">høst</td>
                                    <td id="label_175_vinter">vinter</td>
                                    <td id="label_175_helårsfelt">helårsfelt</td>
                                </tr>
                                <tr>
                                    <td><input class="form-control" formControlName="vår" name="175_vår" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="sommer" name="175_sommer" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="høst" name="175_høst" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="vinter" name="175_vinter" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="helårsfelt" name="175_helårsfelt" type="checkbox"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="field"><textarea name="text" class="form-control" formControlName="text"></textarea></div>
                </div>
                <div class="period" formGroupName="176" [hidden]="!this.polyProps.get('timeframe.176').value">
                    <label>{{this.questions['176']}}: </label>
                    <div class="field">
                        <table class="medium-12 columns seasonselector" formGroupName="seasons">
                            <tbody>
                                <tr class="season">
                                    <td id="label_176_vår">vår</td>
                                    <td id="label_176_sommer">sommer</td>
                                    <td id="label_176_høst">høst</td>
                                    <td id="label_176_vinter">vinter</td>
                                    <td id="label_176_helårsfelt">helårsfelt</td>
                                </tr>
                                <tr>
                                    <td><input class="form-control" formControlName="vår" name="176_vår" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="sommer" name="176_sommer" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="høst" name="176_høst" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="vinter" name="176_vinter" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="helårsfelt" name="176_helårsfelt" type="checkbox"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="field"><textarea name="text" class="form-control" formControlName="text"></textarea></div>
                </div>
                <div class="period" formGroupName="177" [hidden]="!this.polyProps.get('timeframe.177').value">
                    <label>{{this.questions['177']}}: </label>
                    <div class="field">
                        <table class="medium-12 columns seasonselector" formGroupName="seasons">
                            <tbody>
                                <tr class="season">
                                    <td id="label_177_vår">vår</td>
                                    <td id="label_177_sommer">sommer</td>
                                    <td id="label_177_høst">høst</td>
                                    <td id="label_177_vinter">vinter</td>
                                    <td id="label_177_helårsfelt">helårsfelt</td>
                                </tr>
                                <tr>
                                    <td><input class="form-control" formControlName="vår" name="177_vår" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="sommer" name="177_sommer" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="høst" name="177_høst" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="vinter" name="177_vinter" type="checkbox"></td>
                                    <td><input class="form-control" formControlName="helårsfelt" name="177_helårsfelt" type="checkbox"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="field"><textarea name="text" class="form-control" formControlName="text"></textarea></div>
                </div>
                <div class="period" formGroupName="172">
                    <label>{{this.questions['172']}}: </label>
                    <div class="field"><textarea name="text" class="form-control" formControlName="text"></textarea></div>
                    <label>Year of conflict: </label><input type="number" name="yearofconflict" class="form-control" formControlName="yearofconflict">
                </div>
            </div>
            <div class="actions">
                <!-- <div class="ui red deny grey labeled icon button">remove<i class="trash alternate icon"></i></div> -->
                <button class="ui positive right floated labeled icon button" type="submit">lagre<i class="checkmark icon"></i></button>
                <br><br>
            </div>
        </form>
    </div>
</div>