<app-header></app-header>
<div class="ui pushable segment">
    <app-mobile-menu></app-mobile-menu>
    <div class="pusher">
        <div class="ui container">       
            <h1 class="ui header">answered questionnaires</h1>
            <div class="ui basic button" routerLink="/" ><i class="icon plus"></i>insert new questionnaire</div>

            <div class="ui top attached tabular menu">
              <div class="item active" data-tab="tab1">List</div>
              <div class="item" data-tab="tab2">Tests</div>
              <div class="item" data-tab="tab3">recycle bin</div>
            </div>

            <div class="ui bottom attached active tab segment" data-tab="tab1">
              <table class="ui compact celled definition table">
              <thead>
              <tr>
                      <!-- <th>Sichtbar</th> -->
                      <th></th>
                      <th></th>
                      <th>id</th>
                      <th>autoid</th>
                      <th>date</th>
                      <th>export to</th>
                      <th>versions</th>
                      <th>map</th>
                      <th>edit</th>
              </tr>
              </thead>
              <tbody>
                      <tr *ngFor="let q of questionnaires">
                              <!-- <td class="collapsing"><div class="ui fitted slider checkbox"><input type="checkbox"> <label></label></div></td> -->
                              <td></td>
                              <td><a (click)="removeFromList(q.id)"><i class="trash icon"></i></a> </td>
                              <td>{{q.id}}</td>
                              <td>{{q.autoid}}</td>
                              <td>{{q.date}}</td>
                              <td><a href="https://geosurvey.infodocks.de/api/questionnaires/{{q.id}}" target="_blank" title="save this data set as JSON">JSON</a> | CSV | ODT | XLSX</td>
                              <td><a (click)="openVersions(q.id)">Versions: {{q.count}}</a></td>
                              <td><a routerLink="/map/{{q.id}}/{{q.autoid}}"><i class="map icon"></i></a></td>
                              <td><a routerLink="/q/{{q.id}}"><i class="edit icon"></i></a></td>
  <!--               deactivate this answer             <td><i (click)="openModal(o)" class="trash alternate outline icon"></i></td> -->
                      </tr>
              </tbody>
              </table>
            </div>

            <div class="ui bottom attached tab segment" data-tab="tab2">
              <table class="ui compact celled definition table">
                <thead>
                <tr>
                        <!-- <th>Sichtbar</th> -->
                        <th></th>
                        <th></th>
                        <th>id</th>
                        <th>autoid</th>
                        <th>date</th>
                        <th>export to</th>
                        <th>versions</th>
                        <th>map</th>
                        <th>edit</th>
                </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let q of tests">
                                <!-- <td class="collapsing"><div class="ui fitted slider checkbox"><input type="checkbox"> <label></label></div></td> -->
                                <td></td>
                                <td><a (click)="removeFromTests(q.id)"><i class="trash icon"></i></a> </td>
                                <td>{{q.id}}</td>
                                <td>{{q.autoid}}</td>
                                <td>{{q.date}}</td>
                                <td><a href="https://geosurvey.infodocks.de/api/questionnaires/{{q.id}}" target="_blank" title="save this data set as JSON">JSON</a> | CSV | ODT | XLSX</td>
                                <td><a (click)="openVersions(q.id)">Versions: {{q.count}}</a></td>
                                <td><a routerLink="/map/{{q.id}}/{{q.autoid}}"><i class="map icon"></i></a></td>
                                <td><a routerLink="/q/{{q.id}}"><i class="edit icon"></i></a></td>
    <!--               deactivate this answer             <td><i (click)="openModal(o)" class="trash alternate outline icon"></i></td> -->
                        </tr>
                </tbody>
                </table>
            </div>

            <div class="ui bottom attached tab segment" data-tab="tab3">
              <table class="ui compact celled definition table">
                <thead>
                <tr>
                        <!-- <th>Sichtbar</th> -->
                        <th></th>
                        <th></th>
                        <th>id</th>
                        <th>autoid</th>
                        <th>date</th>
                        <th>export to</th>
                        <th>versions</th>
                        <th>map</th>
                        <th>edit</th>
                        <th>restore</th>
                </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let q of recycle">
                                <!-- <td class="collapsing"><div class="ui fitted slider checkbox"><input type="checkbox"> <label></label></div></td> -->
                                <td></td>
                                <td><a (click)="delete(q.id)"><i class="trash icon"></i></a> </td>
                                <td>{{q.id}}</td>
                                <td>{{q.autoid}}</td>
                                <td>{{q.date}}</td>
                                <td><a href="https://geosurvey.infodocks.de/api/questionnaires/{{q.id}}" target="_blank" title="save this data set as JSON">JSON</a> | CSV | ODT | XLSX</td>
                                <td><a (click)="openVersions(q.id)">Versions: {{q.count}}</a></td>
                                <td><a routerLink="/map/{{q.id}}/{{q.autoid}}"><i class="map icon"></i></a></td>
                                <td><a routerLink="/q/{{q.id}}"><i class="edit icon"></i></a></td>
    <!--               deactivate this answer             <td><i (click)="openModal(o)" class="trash alternate outline icon"></i></td> -->
                                <td><a (click)="restore(q.id)" title="restore"><i class="undo icon"></i></a> </td>
                        </tr>
                </tbody>
                </table>
            </div>

            <div>&nbsp;</div>
        </div>
    </div>
</div>
<app-footer></app-footer>


<div id="versions" class="ui modal">
    <i class="close icon"></i>
    <div class="header">
      Versioning ({{mc.id}})
    </div>
      <div class="description">
        <p>version history of this questionnaire:</p>
        <table class="ui compact celled definition table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>changed</th>
              <th>export to</th>
              <th>map</th>
              <th>edit</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let v of versionsSingleQ">
              <td></td>
              <td><a (click)="removeVersionFromList(v.id)"><i class="trash icon"></i></a> </td>
              <td>{{v.date}}</td>
              <td><a href="https://geosurvey.infodocks.de/api/questionnaires/{{v.id}}" target="_blank" title="save this data set as JSON">JSON</a> | CSV | ODT | XLSX</td>
              <td><a routerLink="/map/{{v.id}}/173"><i class="map icon"></i></a></td>
              <td><a routerLink="/q/{{v.id}}"><i class="edit icon"></i></a></td>
            </tr>
          </tbody>
          </table>
      </div>
    
    <div class="actions">
      <div class="ui positive right labeled icon button">
        close
        <i class="checkmark icon"></i>
      </div>
    </div>

  </div>

<div id="delete" class="ui modal mini">
  <i class="close icon"></i>
  <div class="header">delete</div>
  <div class="content"><p>Do you really want to delete this questionnaire?</p></div>
  <div class="actions">
    <div class="ui cancel button green">Cancel</div>
    <div class="ui approve button red">Yes, delete</div>
  </div>
</div>